









import { Vue, Component, Prop} from 'vue-property-decorator';
import AuthContainer from "@/views/common/auth/AuthContainer.vue";
@Component({ components: {AuthContainer} })
export default class extends Vue {}
