

















import { Vue, Component, Prop} from 'vue-property-decorator';

import {ValidatorRules} from "@/entities/user-entity";
import {ValidateWithScroll} from "@/utils/validation-handler";
import AuthRepository from "@/repositories/auth-repository";
import LoadingHandler from '@/utils/loading-handler';
import AuthContainer from "@/views/common/auth/AuthContainer.vue";

@Component({
  components: {AuthContainer}
})
export default class extends Vue {
  private formData:any = {
    email: "",
  };

  private get rules(){
    return {
      email: ValidatorRules.email
    }
  }

  private async onSubmit() {
    if (!(await ValidateWithScroll(this.$refs.form as any))) return;

    await LoadingHandler(async () => {
      await (new AuthRepository()).sendPasswordResetEmail(this.formData.email);
      this.$message({type: 'success', message: this.$t("パスワード再設定メールが送信されました") });
      await this.$router.push({name: 'auth.forget-password.email-sent'})
    });
  }

}
