
















import { Vue, Component, Prop} from 'vue-property-decorator';
import LoadingHandler from '@/utils/loading-handler';

import {ValidateWithScroll} from "@/utils/validation-handler";
import getPasswordRules, {IConfirmPassword} from "@/views/common/auth/ForgetPassword/password-rule";
import Qs from "qs";
import AuthRepository from "@/repositories/auth-repository";
import AuthContainer from "@/views/common/auth/AuthContainer.vue";
@Component({
  components: {AuthContainer}
})
export default class extends Vue {
  private data:IConfirmPassword = {
    password: "",
    passwordConfirm: "",
  };
  private rules = getPasswordRules(this.data);

  private async onSubmit() {
    if (!(await ValidateWithScroll(this.$refs.form as any))) return;

    return LoadingHandler(async () => {
      const params = Qs.parse(location.search.substring(1)) as any;
      await (new AuthRepository).resetPassword(params, this.data.password)
      await this.$router.push({name: 'auth.forget-password.complete'})
    });

  }
}
