









import LoadingHandler from '@/utils/loading-handler';
import { Vue, Component, Prop} from 'vue-property-decorator';

import Qs from 'qs';
import AuthRepository, {SignupRequest} from "@/repositories/auth-repository";

import AuthContainer from "@/views/common/auth/AuthContainer.vue";
import ProfileForm from "@/views/common/auth/components/ProfileForm.vue";

@Component({ components: {ProfileForm, AuthContainer} })
export default class extends Vue {

  private async submit(data: SignupRequest) {
    LoadingHandler(async () => {
      const params = Qs.parse(location.search.substring(1)) as any;
      const user = await (new AuthRepository).signup(params, data);
      this.$auth.login(user);
    }).then(() => {
      this.$message({type: 'success', message: this.$t("ユーザーが作成されました") });
      this.$router.push({name: 'auth.signup.register-card', query: { type: 'signup' }});
    });
  }
}
