import RepositoryBase, {handleAxiosError} from "@/repositories/repository-base";

export class CouponRepository extends RepositoryBase<Object> {
  public COUPON_LENGTH = 6;

  protected endpoint = "coupons";
  protected ctor:new(data) => Object = Object;

  public async getCoupon(couponCode:string) {
    const res = await super.get("coupons/code/" + couponCode);
    return res.data;
  }

  protected handleErrorWithMessage(error): Promise<any> {
    if (error.response && error.response.status === 404) {
      return Promise.resolve(null);
    }
    return handleAxiosError(error);
  }
}
export default new CouponRepository;
