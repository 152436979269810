






















import { Vue, Component, Prop} from 'vue-property-decorator';
import LoadingHandler from '@/utils/loading-handler';

import {ValidatorRules} from '@/entities/user-entity';

import Qs from 'qs';
import AuthRepository from "@/repositories/auth-repository";
import {ValidateWithScroll} from "@/utils/validation-handler";
import execWithLoading from "@/utils/loading-handler";
import AuthContainer from "@/views/common/auth/AuthContainer.vue";
import Agreement from "@/views/common/auth/components/Agreement.vue";

@Component({
  components: {Agreement, AuthContainer}
})
export default class extends Vue {
  private user = {name: ''};
  private companyName:string = '';

  private password = "";

  private get rules(){
    return {
      password: ValidatorRules.password,
      name: ValidatorRules.name,
    };
  }

  private async created() {
    execWithLoading(async () => {
      const params = Qs.parse(location.search.substring(1));
      const invitation = await (new AuthRepository).fetchInvitingMember(params);
      this.companyName = invitation.companyName;
    });
  }

  private async submit() {
    if (!(await ValidateWithScroll(this.$refs.form as any))) return;

    LoadingHandler(async () => {
      const params = Qs.parse(location.search.substring(1)) as any;
      const user = await (new AuthRepository).signupFromInvitation(params, this.password, this.user)
      this.$auth.login(user);
    }).then(() => {
      this.$message({type: 'success', message: this.$t("ユーザーが作成されました") });
      this.$router.push({name: 'auth.invitation.complete'});
    });
  }
}
