<template>
  <div>
    <p>Logout...</p>
  </div>
</template>

<script>
  import AuthRepository from "@/repositories/auth-repository";
  import LoadingHandler from '@/utils/loading-handler';

  export default {
    async created() {
      LoadingHandler(() => {
        return (new AuthRepository).logout().then(() => {
          this.$auth.logout();

          this.$router.push({name: 'lp'}).then(() => {
            this.$message({type: 'success', message: this.$t('ログアウトしました') });
          });
        });
      });
    }
  };
</script>
