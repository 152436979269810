<template>
  <div class="u-flex page-login l-container">
    <div class="page-login__layout">
      <div class="page-login__inner">
        <router-link :to="{name: 'lp'}" class="page-login__logo">
          <img :src="logo" />
        </router-link>
        <p class="page-login__title" v-html="title"></p>
        <p v-if='desc' class="page-login__description u-mt30" v-html="desc"></p>

        <slot/>
      </div>
    </div>
    <div class="page-login__layout">
      <img :src="rightImageSrc" class="page-login__layout__right__image" />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: String,
      desc: String,
      img: String
    },
    computed: {
      logo() {
        return require(`@/assets/${this.$appType}/header-logo.svg`);
      },
      rightImageSrc() {
        const img = this.img.replace('@/assets/', ''); // @/assets/は直書きしないとうまくloadingされなかったため
        return require(`@/assets/${img}`);
      }
    }
  }
</script>

<style lang="scss">
  @import "objects/pages/page-login";
</style>
