

























import { Vue, Component, Prop} from 'vue-property-decorator';
import LoadingHandler from '@/utils/loading-handler';

import {ValidatorRules} from "@/entities/user-entity";
import pick from 'lodash/pick';
import AuthRepository from "@/repositories/auth-repository";
import {ValidateWithScroll} from "@/utils/validation-handler";
import AuthContainer from "@/views/common/auth/AuthContainer.vue";
import appType, {AppTypes} from "@/app-types";
import Agreement from "@/views/common/auth/components/Agreement.vue";

@Component({
  components: {Agreement, AuthContainer}
})
export default class extends Vue {
  @Prop( {default: '' }) email!:string;

  private formData:any = {
    email: this.email,
  };

  private get rules(){
    return pick(ValidatorRules, ["email"]);
  }
  private get text() {
    return appType === AppTypes.Label ?
      this.$t('無料で 14 日間、全機能をお使いいただくことができます。') :
      this.$t('無料で始めることができます。');
  }

  private async signup() {
    if (!(await ValidateWithScroll(this.$refs.loginForm as any))) return;

    return LoadingHandler(async () => {
      await (new AuthRepository).sendVerifyRegistrationEmail(this.formData.email);
    }).then(() => {
      this.$message({ type: 'success', message: this.$t("確認メールが送信されました") });
      this.$router.push({ name: 'auth.signup.verifying' });
    });
  }
}
