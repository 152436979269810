









































import {Component, Vue} from 'vue-property-decorator';
import LoadingHandler from '@/utils/loading-handler';

import {CardInfo} from '@/utils/credit-card-utils';
import PayJP, {tokenErrors} from '@/libs/payjp';
import PaymentRepository from '@/repositories/company/payment-repository';
import CouponRepository from "@/repositories/coupon-repository";
import {DIContainer} from "@/utils/di-container";

import AuthContainer from "@/views/common/auth/AuthContainer.vue";
import appType, {AppTypes} from "@/app-types";
import {HandledApiError} from "@/bootstraps/error-handler";
import CreditCardFormPayJP from "@/components/CreditCardFormPayJP.vue";

@Component({
  components: {
    CreditCardFormPayJP,
    AuthContainer
  }
})
export default class extends Vue {
  private couponCode:string = "";
  private couponResult:{type, message}|null = null;

  public tokenError:string = "";

  private initialized = false;

  private get startPlan() {
    return this.$t(appType === AppTypes.Label ? 'スタータープラン' : 'フリープラン');
  }
  private get text() {
    return appType === AppTypes.Label ?
      this.$t('無料期間があります。<br>14 日以内のご解約で、費用は発生いたしません。') :
      this.$t('無料プランからスタートすることが可能です。');
  }

  public async created() {
    if (appType === AppTypes.Spec) {
      await LoadingHandler(() => {
        return (new PaymentRepository(this.$auth.user.company.id, appType)).createFreeSubscription();
      });
      location.href = this.$router.resolve({name: 'auth.signup.complete'}).href;
      return;
    }

    this.initialized = true;
  }

  private onCouponCodeChanged() {
    if (this.couponCode.length !== CouponRepository.COUPON_LENGTH) {
      this.couponResult = null;
      return;
    }

    CouponRepository.getCoupon(this.couponCode).then((coupon:any) => {
      if (coupon) {
        this.couponResult = {
          type: 'success',
          message: this.$t(`クーポンが適用されます`) + `（${coupon.message}）`
        }
      } else {
        this.couponResult = {
          type: ['error', 'el-icon-error'],
          message: this.$t('この招待コードは有効ではありません。再度正しいコードを入力ください')
        }
      }
    });
  }

  private inputCompleted = false;
  private cardChanged(completed: boolean) {
    this.inputCompleted = completed;
  }

  public async submit() {
    if (!this.inputCompleted) return;

    LoadingHandler(async () => {
      let token:any = {};

      try {
        const elem = (this.$refs.creditCard as any).payjpElement;
        token = await DIContainer.payjp.createToken(elem);
      } catch (error) {
        this.tokenError = this.$t("トークン作成に失敗しました") + "（code:" + tokenErrors[error.error.code] + "）";
        throw new HandledApiError(this.tokenError);
      }

      await (new PaymentRepository(this.$auth.user.company.id, appType)).createSubscription(token.id, this.couponCode)
        .catch(error => {
          this.tokenError = this.$t("クレジットカードの登録に失敗しました") + "（" + error.message + "）";
          throw error;
        });
    }).then(() => {
      location.href = this.$router.resolve({name: 'auth.signup.complete'}).href;
    });

  }
}
